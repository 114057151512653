import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
          Quelles sont les principales fonctionnalités de ce dashboard financier ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Ce dashboard offre un aperçu en temps réel de la santé financière de l'entreprise, avec des fonctionnalités telles que le suivi des revenus et des dépenses, l'analyse des flux de trésorerie, le suivi des indicateurs clés de performance financiers, et la comparaison des prévisions budgétaires avec les réalisations.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
          Comment puis-je personnaliser le dashboard selon mes besoins ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Vous pouvez personnaliser le dashboard en ajoutant, retirant ou modifiant les widgets et les rapports pour correspondre à vos besoins spécifiques. Utilisez la fonctionnalité de glisser-déposer pour réorganiser les éléments et sélectionnez les indicateurs que vous souhaitez surveiller de près.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
          Comment les données sont-elles mises à jour dans le dashboard ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Les données sont automatiquement mises à jour en temps réel grâce à des intégrations directes avec vos systèmes comptables et financiers. Vous pouvez également configurer des mises à jour manuelles ou périodiques selon les besoins de votre entreprise.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
          Est-ce que le dashboard offre des analyses prédictives ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Oui, le dashboard intègre des outils d'analyse prédictive pour vous aider à anticiper les tendances financières, à évaluer les risques et à prendre des décisions éclairées. Ces analyses s'appuient sur des modèles statistiques et des algorithmes d'apprentissage automatique pour prédire les performances financières futures.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
          Comment puis-je partager les rapports financiers avec mon équipe ou mes investisseurs ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Vous pouvez partager les rapports directement depuis le dashboard via email ou en générant des liens de partage. Il est également possible d'exporter les données sous différents formats pour une distribution facile en dehors de la plateforme.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
